import React from 'react';
import styled from 'styled-components';

// @ts-ignore TODO: configure webpack for SVG assets in TS
// https://webpack.js.org/guides/typescript/#importing-other-assets
import tsLogo from '../../assets/img/svg/ts-logo-128.svg';

const StyledTsLogo = styled.img.attrs({
  src: tsLogo,
  alt: 'Link to type definition on GitHub',
  title: 'See type definition on GitHub',
})`
  height: 1.4285714286em; // yields 20px
  width: auto;
  vertical-align: -5px;
`;

export default StyledTsLogo;
