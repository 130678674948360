/* eslint-disable no-fallthrough */
import React from 'react';
import { Anchor, Box, HStack, useWindowWidth, tokens } from '@carta/ink';
import styled from 'styled-components';
import pj from '../../../package.json';

import { codeStyles } from '../../styles/code';

import StyledTsLogo from './TsLogo';

// Shared styles for all <code> tags
export const CodeWithFontStyles = styled('code')`
  font-family: ${codeStyles.fontFamily};
  font-weight: ${codeStyles.fontWeight};
  font-size: ${codeStyles.fontSize};
`;

// <code> that gets rendered by `md` (green) and used for Props table name
export const CodeWithAllStyles = styled(CodeWithFontStyles)`
  background: ${tokens.color.global.brand['green-30']};
  border-radius: 3px;
  color: ${tokens.color.global.brand['gray-100']};
  display: inline-block;
  padding: 0px 6px;
  white-space: nowrap;
  line-height: 22px;
`;

export const PropNameFontStyles = styled('code')`
  font-family: ${codeStyles.fontFamily};
  font-weight: ${tokens.font.global.weight['heading-2']};
  font-size: ${tokens.font.global.size['heading-2'].mobile};
`;

// Props table `type` code wrapper (slate background)
const TypeWrapper = styled('pre')`
  display: inline-block;
  vertical-align: top;
  padding: 0 6px;
  background: ${tokens.color.global.brand['blue-20']};
  border-radius: 3px;
  color: ${tokens.color.global.brand['blue-80']};
  &:not(:last-child),
  &:not(:only-child) {
    display: inline;
  }
`;

const TypeItem = styled('li')`
  margin: 8px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:only-child {
    margin: 0;
  }
`;

const Type = props => {
  /*
    Renders <pre/> because we don't always need the <li/>
    ---------------
    ul (list needed when prop takes values from a set)
      li (TypeItem)
        pre (Type)
          code (Code)
    ---------------
  */
  return (
    <TypeWrapper>
      <CodeWithFontStyles>{props.children}</CodeWithFontStyles>
    </TypeWrapper>
  );
};

export function TypeScriptType({ prop }) {
  const { isMobile } = useWindowWidth();

  if (!prop) {
    return null;
  }

  switch (prop.name) {
    case 'enum':
      if (prop.raw === 'boolean' || prop.raw === 'ReactNode') {
        return (
          <Box>
            <Type>{prop.raw}</Type>
          </Box>
        );
      }

      if (isMobile) {
        return (
          <HStack spacing="xsmall" wraps>
            {prop.value.map(element => (
              <Type key={element.value}>{element.value}</Type>
            ))}
          </HStack>
        );
      }

      return (
        <ul>
          {prop.value.map(element => (
            <TypeItem key={element.value}>
              <Type>{element.value}</Type>
            </TypeItem>
          ))}
        </ul>
      );
    default:
      return (
        <Box>
          <Type>{prop.name}</Type>{' '}
          {prop.definition && (
            <Anchor
              href={`https://github.com/carta/ink/blob/${pj.dependencies['@carta/ink']}/${prop.definition.fileName}#L${prop.definition.start}-L${prop.definition.end}`}
              target="_blank"
              rel="noopener noreferrer"
              preset="new-tab"
            >
              <StyledTsLogo />
            </Anchor>
          )}
        </Box>
      );
  }
}

export default TypeScriptType;
