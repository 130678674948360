import React from 'react';
import { graphql } from 'gatsby';
import * as Ink from '@carta/ink';
import Layout from '../components/Layout';
import StyledLink from '../components/Common/StyledLink';

import Header from '../components/Header/Header';
import { CodeWithAllStyles } from '../components/Common/PropDisplay';

const GettingStarted = () => {
  return (
    <Layout pageTitle="Getting started">
      <Header
        title="Getting Started"
        subtitle="
          ink is Carta’s design system. It allows Carta to build product UI with great user experience
          at scale. Today, it manifests itself as a collection of React-based UI components infused with the visual and
          interaction design principles we hold most dear."
      />
      <Ink.Box top="32" bottom="32">
        <svg width="200" height="102" viewBox="0 0 200 102" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M72.209 16.567C69.8209 16.567 67.9702 15.9999 66.6567 14.8357C65.3433 13.6715 64.6567 12.0297 64.6567 9.88046V7.55211C64.6567 5.40285 65.3135 3.76106 66.6567 2.59688C68 1.4327 69.8508 0.86554 72.209 0.86554C74.597 0.86554 76.4478 1.4327 77.8209 2.59688C79.1941 3.76106 79.8806 5.40285 79.8806 7.55211V9.88046C79.8806 12.0297 79.1941 13.7014 77.8209 14.8357C76.4478 15.97 74.597 16.567 72.209 16.567ZM78.7761 78.3581C78.7761 79.7014 78.5373 80.567 78.0597 80.9849C77.5821 81.4028 76.6269 81.582 75.2239 81.582H69.1045C67.7015 81.582 66.7463 81.373 66.2687 80.9849C65.7911 80.567 65.5523 79.7014 65.5523 78.3581V26.4775C65.5523 25.1342 65.7911 24.2685 66.2687 23.8506C66.7463 23.4327 67.7015 23.2536 69.1045 23.2536H75.2239C76.6269 23.2536 77.5821 23.4626 78.0597 23.8506C78.5373 24.2387 78.7761 25.1342 78.7761 26.4775V78.3581Z"
            fill="black"
          />
          <path
            d="M119.224 21.6718C125.522 21.6718 130.179 23.4628 133.194 27.015C136.209 30.5673 137.701 35.5225 137.701 41.821V78.3583C137.701 79.7016 137.463 80.5673 136.985 80.9852C136.507 81.4031 135.552 81.5822 134.149 81.5822H128.03C126.627 81.5822 125.672 81.3732 125.194 80.9852C124.716 80.5673 124.478 79.7016 124.478 78.3583V43.8509C124.478 40.4479 123.791 37.821 122.418 36.0001C121.045 34.1792 118.537 33.2837 114.895 33.2837C111.343 33.2837 108.537 34.3882 106.478 36.5673C104.418 38.7464 103.403 41.821 103.403 45.7613V78.3882C103.403 79.7315 103.164 80.5971 102.687 81.015C102.209 81.4329 101.254 81.6121 99.8507 81.6121H93.7313C92.3283 81.6121 91.3731 81.4031 90.8955 81.015C90.4179 80.5971 90.1791 79.7315 90.1791 78.3882V26.4777C90.1791 25.1344 90.4179 24.2688 90.8955 23.8509C91.3731 23.4329 92.3283 23.2538 93.7313 23.2538H99.6418C101.045 23.2538 102 23.4628 102.478 23.8509C102.955 24.2389 103.194 25.1344 103.194 26.4777V28.8061C104.955 26.5374 107.254 24.7464 109.881 23.5822C112.627 22.3285 115.761 21.6718 119.224 21.6718Z"
            fill="black"
          />
          <path
            d="M161.94 46.6268L181.313 25.9104C182.567 24.5671 183.493 23.791 184.09 23.582C184.806 23.3432 185.552 23.2238 186.328 23.2537H194.687C196.478 23.2537 197.373 23.8805 197.373 25.1343C197.373 25.791 197.045 26.4776 196.358 27.1343L178.866 44.7462L199.015 77.7014C199.463 78.5074 199.672 79.194 199.672 79.7014C199.672 80.9552 198.776 81.582 196.985 81.582H188.06C187.015 81.582 186.269 81.4029 185.821 81.0149C185.373 80.6268 184.866 79.9402 184.269 78.8955L169.582 53.9402L161.94 61.791V78.388C161.94 79.7313 161.701 80.597 161.224 81.0149C160.746 81.4328 159.791 81.6119 158.388 81.6119H152.269C150.866 81.6119 149.91 81.4029 149.433 81.0149C148.955 80.6268 148.716 79.7313 148.716 78.388V5.55219C148.716 4.2089 148.955 3.34323 149.433 2.92532C149.91 2.50741 150.866 2.32831 152.269 2.32831H158.388C159.791 2.32831 160.746 2.53726 161.224 2.92532C161.701 3.31338 161.94 4.2089 161.94 5.55219V46.6268Z"
            fill="black"
          />
          <path
            d="M53.194 88.8358C54.2985 88.8358 55.0448 89.0448 55.4329 89.4328C55.8209 89.8209 56 90.6866 56 91.9403V97.4925C56 98.7463 55.8209 99.5821 55.4329 100C55.0448 100.418 54.3284 100.597 53.194 100.597H3.07465C1.97017 100.597 1.2239 100.388 0.835841 100C0.477632 99.5821 0.268677 98.7463 0.268677 97.4925V91.9403C0.268677 90.6866 0.447781 89.8507 0.835841 89.4328C1.2239 89.0149 1.94032 88.8358 3.07465 88.8358H53.194Z"
            fill="#1B98EE"
          />
        </svg>
      </Ink.Box>
      <Ink.Row>
        <Ink.Column mobile="12-of-12" tabletLandscape="8-of-12">
          <Ink.Box bottom="16">
            <Ink.Heading variant="heading-2">How can I get help with ink?</Ink.Heading>
            <Ink.Text>
              During working hours, our team is on call for design and engineering support at the{' '}
              <Ink.Anchor href="https://eshares.slack.com/messages/ink/">#ink channel</Ink.Anchor> on Slack. Make sure
              to include full-screen screenshots of what you're building or provide a test case in{' '}
              <Ink.Anchor href="https://ink.carta.com/playroom/">Playroom</Ink.Anchor> with your support requests. This
              helps us help you.
            </Ink.Text>
          </Ink.Box>
          <Ink.Box bottom="16">
            <Ink.Heading variant="heading-2">How can I contribute to ink?</Ink.Heading>
            <Ink.Text>
              ink welcomes external contributions. To understand how you can contribute to ink in many ways, go to{' '}
              <StyledLink to="/contributing">How to contribute to ink</StyledLink>. As a designer, attend the weekly{' '}
              <strong>ink Design Jam</strong> to propose new or updated components.
            </Ink.Text>
          </Ink.Box>
          <Ink.Box bottom="16">
            <Ink.Heading variant="heading-2">How do I design with ink?</Ink.Heading>
            <Ink.Text>
              ink provides code and no-code design tooling for working with ink. We encourage designers to work within
              the components' constraints as much as possible because constraints allow us to build a system that
              scales.
            </Ink.Text>
            <Ink.Ul>
              <Ink.Li>
                <StyledLink to="/guides/101-playroom">Playroom 101</StyledLink>
              </Ink.Li>
              <Ink.Li>
                <StyledLink to="/guides/101-figma-library">Figma library 101</StyledLink>
              </Ink.Li>
            </Ink.Ul>
          </Ink.Box>
          <Ink.Box bottom="16">
            <Ink.Heading variant="heading-2">How do I code with ink?</Ink.Heading>
            <Ink.Text>
              You can use ink inside <CodeWithAllStyles>carta-web</CodeWithAllStyles> or in a repo outside of{' '}
              <CodeWithAllStyles>carta-web</CodeWithAllStyles>.
            </Ink.Text>
            <Ink.Ul>
              <Ink.Li>
                <Ink.Anchor href="https://github.com/carta/ink/wiki/How-to-use-Ink-in-projects-outside-of-Carta-Web">
                  Using ink in projects outside of carta-web
                </Ink.Anchor>
              </Ink.Li>
            </Ink.Ul>
          </Ink.Box>
        </Ink.Column>
      </Ink.Row>
    </Layout>
  );
};

export default GettingStarted;

export const query = graphql`
  query GettingStartedQuery {
    allContributing: allMdx(filter: { fileAbsolutePath: { regex: "/content/getting-started/" } }) {
      nodes {
        frontmatter {
          title
        }
        slug
      }
    }
  }
`;
