import { tokens } from '@carta/ink';

export const codeStyles = {
  fontFamily: tokens.font.global.family.monospace,
  fontSize: tokens.font.global.size.monospace,
  fontWeight: tokens.font.global.weight.monospace,
  lineHeight: tokens.font.global.leading['body-3'],
} as const;

export const editorStyles = {
  padding: '20px',
  lineHeight: tokens.font.global.leading['body-3'],
} as const;
